/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import React, { useMemo } from "react"
import { Link, navigate } from "gatsby"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { notify, setUser } from "../../../state"
import Form from "./form"
import media from "../../../styles/media"
import PageHeader from "../../elements/PageHeader"

import info from "../../../images/Auth/info-icon.svg"
import underline_v1 from "../../../images/Auth/underline_one.png"
import underline_v2 from "../../../images/Auth/underline_two.png"

const Container = styled.div`
  &.red {
    background-color: rgba(196, 18, 48, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top,
  &.p-bottom {
    padding-bottom: 10vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top,
    &.p-bottom {
      padding-bottom: 15vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-top: 60px;
  h1,
  p {
    color: rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-family: "AvenirLTStdBlack";
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(-2deg);
  }
  p {
    font-family: "butlermedium";
    line-height: 1.75rem;
    a {
      text-decoration: underline;
    }
  }
  ${media.laptop`
    padding-top: 80px;
    h1 {
      font-size: 8rem;
    }
  `}
`

const Protection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  img {
    height: 20px;
    width: auto;
    margin-right: 20px;
  }
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.8rem;
    line-height: 1rem;
  }
`

const MetaNav = styled.div`
  text-align: center;
  padding-top: 2rem;
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.9rem;
    margin-bottom: calc(0.5rem + 5px);
    a {
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        left: -10px;
        bottom: 0;
        transform: translateY(100%);
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      &.v1 {
        &:after {
          background-image: url(${underline_v1});
        }
      }
      &.v2 {
        &:after {
          background-image: url(${underline_v2});
        }
      }
    }
  }
`

const SignInPage = () => {
  const logger = useMemo(() => new Logger(SignInPage.name), [])
  const dispatch = useDispatch()

  const handleFormSubmit = async (values, actions) => {
    const { email, password } = values
    try {
      const user = await Auth.signIn(email, password)
      logger.info(user)
      dispatch(setUser({ user: user.attributes, hasUser: true }))
      dispatch(
        notify({
          message: "You signed in successfully.",
          variant: "success",
        })
      )
      actions.setSubmitting(false)
      setTimeout(() => {
        navigate("/profile")
      }, 500)
    } catch (err) {
      logger.error(err)
      if (err.code === "UserNotConfirmedException") {
        // resend the user their confirmation code
        await Auth.resendSignUp(email)
        navigate("/verify", {
          state: { email, password },
        })
      } else {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          })
        )
      }
      actions.setSubmitting(false)
    }
  }
  return (
    <>
      <Container className="red p-left-l p-bottom">
        <PageHeader />
      </Container>
      <Container className="black p-left p-right p-bottom">
        <Intro>
          <h1>Sign In</h1>
          <p>
            Continue your journey to the Growth Hacker Marketing Masterclass
            with Ryan Holiday and Black Coffee.
          </p>
        </Intro>
        <Form onSubmit={handleFormSubmit} />
        <Protection>
          <img src={info} alt="Sound Idea Sessions | Sign In" />
          <p>Your information is protected</p>
        </Protection>
        <MetaNav>
          <p>
            <Link to="/forgot-password" className="mouse-link v2">
              Forgot password?
            </Link>
          </p>
          <p>
            Don&#39;t have an account? Create one&nbsp;
            <Link to="/sign-up" className="mouse-link v1">
              here
            </Link>
          </p>
        </MetaNav>
      </Container>
    </>
  )
}

export default SignInPage
