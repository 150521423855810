import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import SignInPage from "../components/Auth/SignInPage"

const SignIn = () => {
  const menuItems = [
    // { name: "Sign Up", path: "/sign-up" },
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Sign In" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <SignInPage />
      <Footer back />
    </>
  )
}

export default SignIn
